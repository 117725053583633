/* nunito-300 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../../static/fonts/nunito-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Nunito Light'), local('Nunito-Light'),
    url('../../static/fonts/nunito-v14-latin-300.eot?#iefix') format('embedded-opentype'),
    url('../../static/fonts/nunito-v14-latin-300.woff2') format('woff2'),
    url('../../static/fonts/nunito-v14-latin-300.woff') format('woff'),
    url('../../static/fonts/nunito-v14-latin-300.ttf') format('truetype'),
    url('../../static/fonts/nunito-v14-latin-300.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../static/fonts/nunito-v14-latin-regular.eot');
  src: local('Nunito Regular'), local('Nunito-Regular'),
    url('../../static/fonts/nunito-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('../../static/fonts/nunito-v14-latin-regular.woff2') format('woff2'),
    url('../../static/fonts/nunito-v14-latin-regular.woff') format('woff'),
    url('../../static/fonts/nunito-v14-latin-regular.ttf') format('truetype'),
    url('../../static/fonts/nunito-v14-latin-regular.svg#Nunito') format('svg');
}
/* nunito-600 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../static/fonts/nunito-v14-latin-600.eot');
  src: local('Nunito SemiBold'), local('Nunito-SemiBold'),
    url('../../static/fonts/nunito-v14-latin-600.eot?#iefix') format('embedded-opentype'),
    url('../../static/fonts/nunito-v14-latin-600.woff2') format('woff2'),
    url('../../static/fonts/nunito-v14-latin-600.woff') format('woff'),
    url('../../static/fonts/nunito-v14-latin-600.ttf') format('truetype'),
    url('../../static/fonts/nunito-v14-latin-600.svg#Nunito') format('svg');
}
/* nunito-800 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../../static/fonts/nunito-v14-latin-800.eot');
  src: local('Nunito ExtraBold'), local('Nunito-ExtraBold'),
    url('../../static/fonts/nunito-v14-latin-800.eot?#iefix') format('embedded-opentype'),
    url('../../static/fonts/nunito-v14-latin-800.woff2') format('woff2'),
    url('../../static/fonts/nunito-v14-latin-800.woff') format('woff'),
    url('../../static/fonts/nunito-v14-latin-800.ttf') format('truetype'),
    url('../../static/fonts/nunito-v14-latin-800.svg#Nunito') format('svg');
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../static/fonts/montserrat-v15-latin-regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../../static/fonts/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('../../static/fonts/montserrat-v15-latin-regular.woff2') format('woff2'),
    url('../../static/fonts/montserrat-v15-latin-regular.woff') format('woff'),
    url('../../static/fonts/montserrat-v15-latin-regular.ttf') format('truetype'),
    url('../../static/fonts/montserrat-v15-latin-regular.svg#Montserrat') format('svg');
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../static/fonts/montserrat-v15-latin-600.eot');
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('../../static/fonts/montserrat-v15-latin-600.eot?#iefix') format('embedded-opentype'),
    url('../../static/fonts/montserrat-v15-latin-600.woff2') format('woff2'),
    url('../../static/fonts/montserrat-v15-latin-600.woff') format('woff'),
    url('../../static/fonts/montserrat-v15-latin-600.ttf') format('truetype'),
    url('../../static/fonts/montserrat-v15-latin-600.svg#Montserrat') format('svg');
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../../static/fonts/montserrat-v15-latin-800.eot');
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('../../static/fonts/montserrat-v15-latin-800.eot?#iefix') format('embedded-opentype'),
    url('../../static/fonts/montserrat-v15-latin-800.woff2') format('woff2'),
    url('../../static/fonts/montserrat-v15-latin-800.woff') format('woff'),
    url('../../static/fonts/montserrat-v15-latin-800.ttf') format('truetype'),
    url('../../static/fonts/montserrat-v15-latin-800.svg#Montserrat') format('svg');
}

/* open-sans-condensed-300 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../../static/fonts/open-sans-condensed-v15-latin-300.eot');
  src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'),
    url('../../static/fonts/open-sans-condensed-v15-latin-300.eot?#iefix')
      format('embedded-opentype'),
    url('../../static/fonts/open-sans-condensed-v15-latin-300.woff2') format('woff2'),
    url('../../static/fonts/open-sans-condensed-v15-latin-300.woff') format('woff'),
    url('../../static/fonts/open-sans-condensed-v15-latin-300.ttf') format('truetype'),
    url('../../static/fonts/open-sans-condensed-v15-latin-300.svg#OpenSansCondensed') format('svg');
}
/* open-sans-condensed-700 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../static/fonts/open-sans-condensed-v15-latin-700.eot');
  src: local('Open Sans Condensed Bold'), local('OpenSansCondensed-Bold'),
    url('../../static/fonts/open-sans-condensed-v15-latin-700.eot?#iefix')
      format('embedded-opentype'),
    url('../../static/fonts/open-sans-condensed-v15-latin-700.woff2') format('woff2'),
    url('../../static/fonts/open-sans-condensed-v15-latin-700.woff') format('woff'),
    url('../../static/fonts/open-sans-condensed-v15-latin-700.ttf') format('truetype'),
    url('../../static/fonts/open-sans-condensed-v15-latin-700.svg#OpenSansCondensed') format('svg');
}
