/**
 * Button
 */
.button {
  display: inline-block;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0 24px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.link-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0 24px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.button-nav {
  height: 48px;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  svg {
    width: 18px;
    height: 13px;
  }
  &:focus {
    outline: 0;
    box-shadow: 0 0 5px transparent;
  }
  &:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #2176d8;
  }
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 118, 216, 50%);
  }
}
.link-group {
  display: flex;
  flex-direction: row;
  *:not(:last-child) {
    margin-right: 8px;
  }
}
.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  svg {
    margin-left: 4px;
    transform: rotate(-90deg);
  }
}
.primary-button {
  background-color: #2176d8;
  height: 48px;
  color: white;
  border-radius: 4px;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  border-width: 2px;
  border-style: hidden;

  &:focus {
    outline: 0;
    box-shadow: 0 0 5px transparent;
  }
  &:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #2176d8;
    border-color: #2176d8;
  }
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 118, 216, 50%);
  }
}

.secondary-button {
  background-color: transparent;
  height: 48px;
  min-width: 170px;
  border: 1px solid #358ff7;
  color: #358ff7 !important;
  border-radius: 4px;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  &:focus {
    outline: 0;
    box-shadow: 0 0 5px transparent;
  }
  &:hover {
    outline: 0;
    box-shadow: 0 0 5px transparent;
    background-color: transparent;
    color: #358ff7;
    border-color: #358ff7;
  }
}
.secondary-button-light {
  background-color: transparent;
  height: 48px;
  width: 100%;
  min-width: 170px;
  border: 1px solid #070708;
  color: #070708;
  border-radius: 4px;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  &:focus {
    outline: 0;
    box-shadow: 0 0 5px transparent;
  }
  &:hover {
    outline: 0;
    box-shadow: 0 0 5px transparent;
    background-color: transparent;
    color: #358ff7;
    border-color: #358ff7;
  }
}

.navbar .secondary-button {
  margin-left: 0.46rem;
  margin-right: 0.46rem;
  width: 170px;
  min-width: 170px;
}
