.contact-form > div,
.nextcloud-form > div,
.managed-hosting-form > div {
  padding: 16px;
}
.contact-form,
.nextcloud-form,
.managed-hosting-form {
  color: rgb(36, 38, 43);
  width: 100%;
  position: relative;
  a {
    margin: 12px;
  }
  .form-message {
    position: absolute;
    background-color: white;
    width: 100%;
    height: calc(100% - 46px);
    box-sizing: border-box;
    z-index: 0;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 48px;
    text-align: center;
    svg {
      width: 128px;
      height: 128px;
    }
    &.active {
      z-index: 1000;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.185s ease-in-out;
    }
  }
  .form-message-inline {
    position: absolute;
    background-color: transparent;
    box-sizing: border-box;
    z-index: 0;
    top: 164px;
    left: 0px;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 64%;
    @media only screen and (min-width: 992px) {
      top: 84px;
    }
    .form-message-figure {
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 32px;
        height: 32px;
      }
    }
    p {
      margin: 0;
      color: #fff;
    }
    &.active {
      z-index: 1000;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.185s ease-in-out;
    }
  }
  .form-group {
    display: flex;
    flex-direction: column;
    background-color: white;
    .form-textareas-group {
      textarea {
        width: 100%;
        padding: 8px 8px 0;
      }
    }
    .inline-form {
      display: flex;
      flex-direction: column;
      @media only screen and (min-width: 992px) {
        flex-direction: row;
      }
    }
    p {
      margin-bottom: 2px;
    }
    .form-control {
      height: 42px;
      margin-bottom: 16px;
      padding: 4px 8px;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(118, 118, 118);
      border-radius: 3px;
    }
  }
  .form-button-group {
    display: flex;
    justify-content: flex-end;
    button > span {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .spinner {
        display: none;
        opacity: 0;
        visibility: hidden;
        &.active {
          display: block;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .form-check {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 42px;
    .form-check-input {
      margin-right: 6px;
    }
    .form-check-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      span {
        margin: 0;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 104px;
          *:not(:last-child) {
            margin-right: 4px;
          }
          .form-package-group {
            display: flex;
            flex-direction: column;
            .form-package-type {
              font-family: 'Nunito', Arial, Helvetica, sans-serif;
              font-style: normal;
              font-weight: 300;
              font-size: 9px;
              line-height: 1;
            }
            div {
              .form-package-spec {
                font-family: 'Nunito', Arial, Helvetica, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 1;
              }
              .form-package-unit {
                font-family: 'Nunito', Arial, Helvetica, sans-serif;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }
}
