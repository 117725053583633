body {
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
  @media only screen and (min-width: 992px) {
    grid-template-columns: 1fr;
  }
  &.nav-padding {
    padding-top: 100px;
  }
}

header {
  grid-area: header;
}

main {
  grid-area: main;
}

footer {
  margin-top: 48px;
  grid-area: footer;
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  &.full {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 !important;

    .heroine-small-image {
      width: auto !important;
      height: 400px;
      display: flex;
      align-items: center;
      img {
        width: 400px !important;
        padding: 0 64px;
      }
    }
    .row {
      padding: 0 24px;
      margin-top: 32px;
      margin-bottom: 48px;
    }
    @media only screen and (min-width: 992px) {
      .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 0 !important;
      }
    }
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      color: #fff;
      margin-bottom: 24px;
      svg {
        transform: rotate(-90deg);
        margin-left: 4px;
      }
      svg polyline {
        stroke: #fff;
      }
    }
  }
}

.container {
  width: 100%;
  padding: 0 24px;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (min-width: 992px) {
    width: 1100px;
  }
}
.container,
.container-fluid {
  padding: 0 24px;
  margin-top: 32px;
  margin-bottom: 48px;
  .row {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media only screen and (min-width: 992px) {
      flex-direction: row;
    }
    &.third {
      iframe {
        width: 100%;
      }
      .column:first-of-type {
        width: 100%;
      }
      .column:last-of-type {
        width: 100%;
      }
      @media only screen and (min-width: 992px) {
        .column:first-of-type {
          width: auto;
        }
        .column:last-of-type {
          width: 60%;
        }
      }
    }
    .column {
      width: 100%;
      picture {
        margin: 0 auto;
      }
      &:not(:last-child) {
        margin-right: 0px;
      }
      &:last-child {
        margin-top: 24px;
        margin-bottom: 24px;
      }
      img {
        width: 100%;
        height: auto;
      }
      @media only screen and (min-width: 992px) {
        width: -webkit-fill-available;
        &:not(:last-child) {
          margin-right: 24px;
        }
        &:last-child {
          margin-top: 0px;
        }
      }
      .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-gap: 1rem;
        &.three-columns {
          grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)) !important;
        }
        &.four-columns {
          grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)) !important;
        }
        div .product-description-group {
          color: $fontSecondaryColor;
        }
        & > div {
          background-color: transparent;
        }
        & > div::before {
          content: '';
          display: block;
        }
      }
    }
  }
  &.heroine {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #0c0c0d;
    background: linear-gradient(0deg, #09090a, #0e1421);
    padding: 100px 24px 0;
    margin-top: 0 !important;
    @media only screen and (min-width: 992px) {
      height: 80vh;
    }
    &.background-light {
      background: rgb(234, 240, 241) !important;
      a {
        svg path {
          fill: #24262b;
        }
        color: rgb(198, 206, 225) !important;
      }
    }
    &.half {
      height: 40vh;
    }
    h1 {
      font-family: 'Nunito', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 2.5rem;
      letter-spacing: -0.039em;
      color: inherit;
    }
    svg {
      &.heroine-figure {
        width: 320px;
        height: auto;
        margin: 24px auto 48px;
        display: block;
        @media only screen and (min-width: 992px) {
          margin: 0 auto;
        }
      }
    }
    .column:first-of-type {
      margin-left: 0;
      order: 1;
      @media only screen and (min-width: 992px) {
        order: 0;
        margin-left: 10% !important;
      }
    }
    .column:last-of-type {
      margin-right: 0;
      display: flex;
      @media only screen and (min-width: 992px) {
        margin-right: 10% !important;
      }
    }
  }
}
