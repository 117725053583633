nav {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  height: 100px;
  width: 100%;
  z-index: 10000000000;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  background-color: rgba(11, 14, 23, 0.95) !important;
  position: relative;
  @media (min-width: 992px) {
    justify-content: flex-start;
  }
  &.background-light {
    background-color: rgba(232, 234, 240, 0) !important;

    &.fade-in {
      box-shadow: 0px -20px 10px 20px #64719e;
      background-color: rgba(232, 234, 240, 0.95) !important;
    }
    .button-nav {
      svg g {
        fill: rgba(11, 14, 23, 0.95) !important;
      }
    }
    .nav-bar-navigation-simple {
      background-color: rgba(232, 234, 240, 0.95) !important;
      .nav-bar-navigation-section-title,
      .nav-bar-navigation-simple-group-byline {
        color: rgba(11, 14, 23, 0.95) !important;
      }
    }
    a {
      svg path {
        fill: rgb(36, 38, 43);
      }
      svg polyline {
        stroke: rgb(36, 38, 43);
      }
      color: rgb(36, 38, 43) !important;
      &:hover,
      &:active {
        color: scale-color(rgb(36, 38, 43), $lightness: 70%);
      }
      &:focus {
        color: scale-color(rgb(36, 38, 43), $lightness: 70%);
        outline: none;
      }
    }
  }
  &.normal {
    position: relative;
    top: 0;
    left: 0;
    opacity: 1;
  }
  &.fixed {
    animation-name: topToBottom;
    animation-duration: 0.3s;
    position: fixed;
    top: 0;
    left: 0;
  }
  &.fade-in {
    animation-name: topToBottom;
    animation-duration: 0.3s;
    box-shadow: 0px -20px 10px 20px #060913;
    background-color: rgba(14, 20, 33, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
  }
  &.fade-out {
    animation-name: bottomToTop;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    position: fixed;
    top: 0;
    left: 0;
  }
  .nav-logo {
    display: flex;
    padding: 0;
    margin-right: 16px;
    .navbar-brand-avatar {
      padding: 0;
      width: 48px;
      height: 48px;
    }
    .navbar-brand-text {
      display: block;
      width: 217px;
      height: 48px;
      @media (min-width: 992px) {
        display: none;
      }
      @media (max-width: 414px) {
        display: none;
      }
    }
  }
  button {
    &.navbar-toggle {
      span {
        color: white;
        display: flex;
        align-items: center;
        svg {
          margin-left: 8px;
        }
      }
      @media (min-width: 992px) {
        display: none;
      }
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    &.nav-bar-navigation {
      display: flex;
      flex-direction: row;
      @media (max-width: 992px) {
        display: none;
      }
      li:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
  a {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600 !important;
    letter-spacing: 0.04rem;
    transition: all 0.3s ease-out;
    color: white;
    padding: 0.19em 0.65em;
    border-radius: 0.2em;
    margin-top: auto;
    margin-bottom: auto;
    user-select: none;
    display: flex;
    align-items: center;
    svg {
      margin-left: 6px;
      polyline {
        color: rgb(255, 255, 225) !important;
      }
    }
    &:hover svg polyline,
    &:focus svg polyline {
    }
    &:hover {
      cursor: pointer;
    }
    &:hover,
    &:active {
    }
    &:focus {
      background-color: rgba(148, 169, 220, 0.19);
      outline: none;
    }
  }
  .nav-bar-navigation-simple {
    position: absolute;
    background-color: rgba(5, 6, 10, 0.97) !important;
    width: 100%;
    top: 100px;
    left: 0;
    visibility: hidden;
    opacity: 0;
    display: none;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    margin: 0;
    @media (min-width: 992px) {
      display: none !important;
    }
    &.expanded {
      display: block;
      visibility: visible;
      opacity: 1;
    }
    li {
      margin: 8px 0;
      padding: 4px 0;
      .nav-bar-navigation-section-title {
        color: scale-color(rgb(36, 38, 43), $lightness: 70%);
      }
      .nav-bar-navigation-simple-group-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .nav-bar-navigation-simple-group-byline {
          color: scale-color(rgb(36, 38, 43), $lightness: 70%);
          font-size: 12px;
          font-weight: 400 !important;
          letter-spacing: 0.04rem;
          margin: 0;
        }
      }
    }
  }
  .nav-bar-navigation {
    .dropdown {
      position: relative;
      .dropdown-menu {
        visibility: hidden;
        opacity: 0;
        border-width: 0px;
        box-shadow: 0 25px 50px -10px rgba(50, 50, 93, 0.25), 0 10px 20px -10px rgba(0, 0, 0, 0.3),
          0 -9px 30px -5px rgba(0, 0, 0, 0.025);
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 0.4rem;
        transition: opacity 0.185ms ease-in-out;
        &.show {
          visibility: visible;
          opacity: 1;
          top: 32px;
          transition: opacity 0.185s ease-in-out;
        }
        li {
          margin: 0;
        }
        a {
          &.dropdown-item {
            padding: 0.65rem 1.5rem;
          }
        }
        .dropdown-divider {
          height: 0;
          margin: 0.5rem 0;
          overflow: hidden;
          border-top: 1px solid #e9ecef;
        }
        .dropdown-item {
          display: block;
          width: 100%;
          clear: both;
          font-weight: 400;
          color: #212529 !important;
          text-align: inherit;
          white-space: nowrap;
          background-color: transparent;
          border: 0;
          &:focus,
          &:hover {
            color: #16181b;
            text-decoration: none;
            background-color: #f8f9fa;
          }
        }
        .dropdown-group {
          display: flex;
          max-width: 400px;
          width: 400px;
          flex-wrap: wrap;
          padding: 0.65rem 1.5rem;
          justify-content: space-between;
          flex-direction: row;
          li {
            margin: 0;
            padding: 0;
            width: 50%;
            a {
              font-family: 'Montserrat', sans-serif;
              font-size: 14px;
              font-weight: 600 !important;
              letter-spacing: 0.04rem;
              transition: all 0.3s ease-out;
              color: #212529 !important;
              padding: 0.19em 0.65em;
              border-radius: 0.2em;
              margin-top: auto;
              margin-bottom: auto;
              user-select: none;
              display: block;
              &:hover {
                cursor: pointer;
              }
              &:hover,
              &:active {
                color: scale-color(#212529, $lightness: 30%);
              }
              &:focus {
                background-color: rgba(148, 169, 220, 0.19);
                color: scale-color(#212529, $lightness: 70%);
                outline: none;
              }
            }
          }
          .dropdown-group-item {
            margin: 0;
            padding: 0;
            .dropdown-group-byline {
              font-size: 12px;
              font-weight: 400 !important;
              color: #212529;
              letter-spacing: 0.04rem;
              margin: 0;
            }
          }
        }
      }
    }
  }
  .nav-link-group {
    display: flex;
    a {
      transition: all 0.3s ease-out;
      color: rgb(198, 206, 225);
      padding: 0.19em 0.65em;
      border-radius: 0.2em;
      margin-top: auto;
      margin-bottom: auto;
      user-select: none;
      &:hover {
        cursor: pointer;
      }
      &:hover,
      &:active {
        color: scale-color($linkSecondaryColor, $lightness: 70%);
      }
      &:focus {
        background-color: rgba(148, 169, 220, 0.19);
        color: scale-color($linkSecondaryColor, $lightness: 70%);
        outline: none;
      }
    }
    a.started {
      transition: all 0.3s ease-out;
      background-color: #b5ff00;
      color: #0c152d;
      box-shadow: 0px 0px 16px #0085ff57;
      padding: 0.19em 0.65em;
      border-radius: 0.2em;
      margin-top: auto;
      margin-bottom: auto;
      user-select: none;
      &:hover {
        cursor: pointer;
      }
      &:hover,
      &:active {
        background-color: saturate(#b5ff00, 10%);
        color: saturate(#0c152d, 10%);
        box-shadow: 0px 0px 16px saturate(#0085ff57, 10%);
      }
      &:focus {
        background-color: rgba(148, 169, 220, 0.19);
        color: scale-color($linkSecondaryColor, $lightness: 70%);
        outline: none;
      }
    }
  }
}
