.slider {
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0 0 16px;
  &.background-white {
    background-color: #fff;
    color: $fontPrimaryColor;
  }
  .slider-group {
    width: 100%;
    overflow: hidden;
    .slider-items-group {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      &.left-to-right {
        animation-name: sliderLeftToRight;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
      }
      &.right-to-left {
        animation-name: sliderRightToLeft;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
      }
      @media (min-width: 992px) {
        flex-direction: row;
      }
      .slide-item {
        display: flex;
        flex-direction: column;
        /*visibility: hidden;
        opacity: 0; */
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        @media (min-width: 992px) {
          flex-direction: row;
          height: 410px;
        }
        &.active {
          visibility: visible;
          opacity: 1;
        }
        .slide-promo-image {
          width: auto;
          height: 100%;
          img {
            width: 100%;
            height: auto;
            @media (min-width: 992px) {
              width: auto;
              height: 100%;
            }
          }
        }
        .slide-promo-description-group {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          .slide-promo-description-group-image {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            margin-top: 24px;
            padding-left: 24px;
            padding-right: 24px;
            img {
              width: 220px;
            }
          }
          .slide-promo-description-group-text {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 24px;
            padding-left: 24px;
            padding-right: 24px;
            a {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              color: $fontPrimaryColor;
              margin-bottom: 24px;
              svg {
                transform: rotate(-90deg);
              }
              svg polyline {
                stroke: $fontPrimaryColor;
              }
            }
          }
        }
      }
    }
  }
  .slider-paginator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    width: 100%;
    margin-top: 8px;
    bottom: -39px;
    .slider-dot {
      border: 3px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      width: 18px;
      height: 18px;
      &.active {
        background-color: #fff;
      }
    }
    *:not(:last-child) {
      margin-right: 16px;
    }
  }
}
