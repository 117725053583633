.background-dark {
  background-color: $backgroundColorDark;
  color: $fontSecondaryColor;

  a {
    color: $linkSecondaryColor;
  }
  .button-nav {
    span {
      color: $fontSecondaryColor;
    }
    svg g {
      fill: $fontSecondaryColor !important;
    }
  }
  .dropship-footer-nav {
    background-color: $backgroundColorDark !important;
    a {
      color: $linkSecondaryColor;
    }
  }
}

.background-light {
  background-color: $backgroundColorLight;
  color: $fontPrimaryColor;
  a {
    color: $linkPrimaryColor;
  }
  .button-nav {
    span {
      color: $fontPrimaryColor;
    }
    svg g {
      fill: $fontPrimaryColor !important;
    }
  }
  .dropship-footer-brand {
    a {
      svg path {
        fill: $linkPrimaryColor !important;
      }
    }
    ul li a {
      color: $linkSecondaryColor !important;
    }
    .icon-phone {
      path {
        fill: $linkPrimaryColor !important;
      }
    }
    .icon-mail {
      path {
        fill: $linkPrimaryColor !important;
      }
    }
  }
  .dropship-footer-nav {
    background-color: $backgroundColorLight !important;
    a {
      color: $linkPrimaryColor !important;
    }
  }
}
