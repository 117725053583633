.package {
  background-color: white;
  color: #09090a;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
  h2.package-title {
  }
  .package-all-inclusive {
    li.empty {
      list-style-type: none;
    }
  }
  .package-description-group {
    display: flex;
    flex-direction: column;
    margin: 0 0 16px;
    p {
    }
    .package-title {
      text-align: center;
    }
    .package-description {
      &.min64 {
        height: 64px;
      }
      &.min86 {
        height: 86px;
      }
    }
    .package-cost {
      display: block;
      min-height: 62px;
      width: 100%;
      .package-varies {
        text-align: center;
      }
      .package-price {
        text-align: center;
        font-family: 'Open Sans', Arial, Helvetica, sans-serif;
        font-size: 48px;
        letter-spacing: -0.1332rem;
        line-height: 1;
        font-weight: 700;
        .package-unit {
          font-family: 'Nunito', Arial, Helvetica, sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 1;
          letter-spacing: 0rem;
        }
      }
    }
  }
  .package-divider {
    width: calc(100% + 32px);
    margin: 0 -16px;
    hr {
      border-color: #efefef;
      border-style: solid;
    }
  }
  .package-content-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .package-content-item {
      display: flex;
      align-items: center;
      width: 50%;
      margin-bottom: 6px;
      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      &.single {
        width: 100%;
      }
      svg {
        margin-right: 8px;
      }
      .package-list-icon {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
      .package-content-item-label {
        font-family: 'Nunito', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 1;
        margin-bottom: 3px;
      }
      .package-content-item-description {
        font-family: 'Nunito', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 1;
      }
    }
  }
  .package-link-group {
    text-align: right;
    a {
      width: 100%;
    }
  }
}
.column {
  &.package:not(:last-child) {
    margin-bottom: 16px;
  }
  &.package:last-child {
    margin-top: 0px !important;
  }
}
