html {
  box-sizing: border-box;
  font-size: 97.5%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  line-height: 1.4;
}

a {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  text-decoration: none;

  img {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
  font-weight: 300;
}

h1 {
  font-size: 34px;
  letter-spacing: -0.02853em;
  line-height: 1.2;
  margin: 0 0 16px;
}

h2 {
  font-style: normal;
  font-size: 28px;
  letter-spacing: -0.02853em;
  line-height: 1.1;
  margin: 8px 0 16px;
}

h3 {
  font-style: normal;
  font-size: 24px;
  letter-spacing: -0.02853em;
  line-height: 1.1;
  margin: 8px 0 8px;
}

h6 {
  font-size: 1.2em;
  margin: 0.67em 0 0.1em;
  letter-spacing: -0.02853em;
  line-height: 1;
}
.d-flex {
  display: flex !important;
}
.align-start {
  align-items: flex-start !important;
}
.align-end {
  align-items: flex-end !important;
}
.justify-center {
  justify-content: center !important;
}
.align-center {
  align-items: center !important;
}
.flex-space-arround {
  justify-content: space-around;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-start {
  align-items: flex-start !important;
}
.justify-right {
  justify-content: flex-end;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}
.hidden {
  visibility: hidden;
  opacity: 0;
}
.display-none {
  display: none;
}
.rotate-right {
  transform: rotate(-90deg);
}
.sub {
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0.0064rem;
}
.icon16 {
  width: 16px !important;
  height: 16px !important;
}
.icon24 {
  width: 24px !important;
  height: 24px !important;
}
.icon32 {
  width: 32px !important;
  height: 32px !important;
}
.min-height480 {
  min-height: 480px !important;
}
.min-height512 {
  min-height: 512px !important;
}
main {
  flex: 1;
}
label {
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.02592em;
  color: inherit;
  font-size: 1em;
}
span {
  &.tag-label {
    color: rgba(114, 180, 255, 62%);
  }
}
p {
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin: 8px 0 16px;
  letter-spacing: 0.01923em;
  color: inherit;
  font-size: 16px;
  line-height: 1.3;
  a {
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    color: $linkPrimaryColor;
  }

  &.light {
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 300;
  }

  &.light-inverted {
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 0.9em;
    margin: 1em 0;
    letter-spacing: 0;
    color: #002358 !important;
  }
}
.text-center {
  text-align: center;
}
strong {
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
}

section {
  min-height: 0;
}
@media (min-width: 992px) {
}

.hours-of-operation {
  padding: 0;
  margin: 0 0 8px;
  list-style-type: none;
  li {
    display: flex;
    justify-content: space-between;
    padding: 0 4px 10px 0;
  }
}
.hours-of-operation-closed {
  background-color: rgb(218, 218, 218);
  border-radius: 6px;
  padding: 4px 8px;
}
.contact-phone,
.contact-mail {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .phone-icon,
  .mail-icon {
    margin-right: 6px;
    width: 24px;
    height: 24px;
  }
}
.tag {
  text-align: center;
  margin: 1.5em 0 0.87em;
}
.avatar-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .avatar-card {
    background-color: white;
    width: 240px;
    position: relative;
    height: 210px;
    display: flex;
    .avatar {
      position: absolute;
      clip: rect(0px, 240px, auto, 0px);
      height: 150px;
      img {
        width: 100%;
        height: auto;
        position: relative;
        top: -35px;
      }
    }
    .avatar-text-group {
      padding: 0 16px;
      margin: 8px 0;
      color: $fontPrimaryColor;
      width: 100%;
      bottom: 0;
      position: absolute;
    }
  }
  .avatar-card:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}
.background-dark {
  .dark-fill {
    fill: #f6fcff !important;
  }
}

.background-inverted {
  background-color: #f6fcff;
  color: #002358;
  .dark-stroke {
    stroke: #0d162e;
  }
  .dark-fill {
    fill: #0d162e !important;
  }
  a {
    color: #002358;

    &:hover {
      color: #103061;
      text-decoration: none;
    }
  }

  .projects li a {
    color: #002358;

    &:hover {
      color: #103061;
      text-decoration: none;
      border: 1px solid #103061;
    }
  }

  &.fade-in {
    box-shadow: 0 -20px 10px 20px #8c9bce;
  }

  p {
    color: #002358 !important;
  }

  > .a-splash {
    height: unset;
  }
}

@media (min-width: 992px) {
  .a-double {
    flex-direction: row;
    margin: 3rem auto 6rem;
    .column {
      width: calc(50%);
      flex-direction: column;
      .sandpiper-logo {
        margin: 6rem 0 0;
        width: 15rem;
        height: 4rem;
      }

      &.display-flex {
        display: flex;
      }

      &.align-center {
        align-items: center;
      }
    }
  }
}
