footer {
  .dropship-footer-group {
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .dropship-footer-brand {
      width: 310px;
      max-width: 310px;
      min-width: 310px;
      margin-bottom: 2rem;
      padding: 0;
      list-style-type: none;
      margin-left: 0;
      .orestad-logo {
        .navbar-brand-avatar {
          width: 48px;
          height: 100%;
        }
        .navbar-brand-text {
          display: inline-block;
          width: 217px;
          height: 48px;
        }
      }
      li {
        address {
          font-size: 16px;
          font-style: normal;
          line-height: 1.5;
          color: inherit;
          a {
            display: inline-flex;
            align-items: center;
          }
        }
        a {
          margin-bottom: 0.35rem;
        }
      }
      .icon-phone {
        width: 22px;
        height: 22px;
        margin-right: 0.36rem;
        padding: 0.13rem;
        path {
          fill: white;
        }
      }
      .icon-mail {
        width: 22px;
        height: 22px;
        margin-right: 0.36rem;
        padding: 0.13rem;
        path {
          fill: white;
        }
      }
    }
    .dropship-footer-nav {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0;
      padding: 0;
      flex-grow: 1;
      height: 100%;
      z-index: 100000;
      ul {
        padding: 0 16px;
        list-style-type: none;
        padding-inline-start: 0px;
        margin-right: 1rem;
        margin-bottom: 1rem;
        margin-left: 0;

        .footer-label {
          color: #a8a8b1;
          text-transform: uppercase;
          font-family: 'Montserrat', sans-serif;
          font-weight: 600;
        }
        li {
          display: flex;
          flex-direction: column;
          margin-bottom: 0.8rem;
          a {
            font-family: 'Montserrat', sans-serif;
            text-transform: uppercase;
            color: inherit;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.04rem;
          }
        }
      }
    }
  }
  .copyright {
    display: flex;
    justify-content: center;
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
}

@media only screen and (min-width: 922px) {
  .dropship-footer-group {
    flex-direction: row !important;
    .dropship-footer-nav {
      width: calc(100% - 342px);
    }
  }
}
